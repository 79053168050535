<script setup lang="ts">
const { closeModal } = useModalStore();
const { isLoading, setLoadingPending, setLoadingSuccess, setLoadingFailed } =
  useLoading();
const $api = useApi();

const props = defineProps({
  foodSubFamily: {
    type: Object,
    default: () => {},
    required: true,
  },
  onConfirm: {
    type: Function,
    default: () => {},
  },
});

const availablePortions = ref([]);
const portionId = ref();

async function fetchPortions(search: String) {
  setLoadingPending();
  try {
    const { data } = await $api.post(`/portions/all`, {
      search,
      page: 1,
    });

    availablePortions.value = data;
    setLoadingSuccess();
  } catch (e) {
    setLoadingFailed();
  }
}
async function handleAddPortionToFoodSubFamily() {
  const { data } = await $api.post(
    `/food/subfamily/${props.foodSubFamily.id}/portions`,
    {
      portionId: portionId.value,
    },
  );
  props.onConfirm(portionId.value);
  closeModal();
}

fetchPortions();
</script>

<template>
  <BaseModal
    :title="`Ajouter une portion à la sous-famille ${props.foodSubFamily.label}`"
    :width="900"
  >
    <BaseSelect
      v-model="portionId"
      placeholder="Poignée, verre, litre, ..."
      type="text"
      label="Recherche une portion"
      :options="
        availablePortions.map((p) => ({
          label: `${p.label} - ${p.quantity}${p.unit.symbol}`,
          value: p.id,
        }))
      "
      :remote-call="fetchPortions"
      :is-loading="isLoading"
      clearable
      debounce
    ></BaseSelect>

    <template #footer>
      <BaseButton text="Annuler" type="secondary" @click="closeModal" />
      <BaseButton text="Confirmer" @click="handleAddPortionToFoodSubFamily" />
    </template>
  </BaseModal>
</template>

<style scoped></style>
